import axios from "axios";

const baseUrl = process.env.REACT_APP_ORDERS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");

export const getAllPricing = async () => {
  let response;
  const params = {
    page: 1,
    limit: 100,
  };
  await axios
    .get(`${baseUrl}/api/v1/prices/get-all-prices`, {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getPricingById = async (id: string) => {
  const token = localStorage.getItem("token");
  //console.log("token", token);
  let response;

  await axios
    .get(`${baseUrl}/api/v1/prices/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updatePricing = async (id: string, reqBody: any) => {
  let response;
  const params = {
    table: "prices",
  };
  await axios
    .patch(`${baseUrl}/api/v1/prices/${id}`, reqBody, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    })
    .then((res) => {
      console.log("res: ", res);
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const createPricing = async (reqBody: any) => {
  let response;

  await axios
    .post(`${baseUrl}/api/v1/prices/create-price`, reqBody, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log("res: ", res);
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const deletePricing = async (id: string) => {
  let response: any;

  await axios
    .delete(`${baseUrl}/api/v1/prices/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log("delete price res: ", res);
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
