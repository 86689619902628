import {
  CREATE_REFERRAL_PLAN,
  GET_ALL_REFERRAL_PLANS,
  GET_REFERRAL_PLAN,
  UPDATE_REFERRAL_PLAN,
  GET_USER_REFERRAL_TRACKING,
} from "../actionTypes/actionTypes";
import { ReferralData, ReferralTrackType } from "../types/discounts";

interface StateType {
  referralPlans: ReferralData[];
  referralPlan: ReferralData;
  referralTrackData: ReferralTrackType[];
}

const INITIAL_STATE: StateType = {
  referralPlans: [],
  referralPlan: {
    name: "",
    discount_id: 0,
    referral_count_required: 0,
    status: "",
    country_code: "",
    region_code: "",
    created_at: "",
    updated_at: "",
  },
  referralTrackData: [],
};

const referralPlansReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_REFERRAL_PLANS:
      return Object.assign({}, state, { discounts: payload });
    case GET_REFERRAL_PLAN:
      return Object.assign({}, state, { discount: payload });
    case CREATE_REFERRAL_PLAN:
      return Object.assign({}, state, { discount: payload });
    case UPDATE_REFERRAL_PLAN:
      return Object.assign({}, state, { discount: payload });
    case GET_USER_REFERRAL_TRACKING:
      return Object.assign({}, state, { referralTrackData: payload });
    default:
      return state;
  }
};

export default referralPlansReducer;
