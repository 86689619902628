import {
  Box,
  Typography,
  CircularProgress,
  TableFooter,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Paper from "@mui/material/Paper";
import { getAllDrivers } from "../../apis/drivers";
import { useTheme } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import "./style.css";

const DriverDocumentation = () => {
  const { drivers } = useSelector((state: any) => state.driverReducer);
  const navigate = useNavigate();

  const [selected, setSelected] = useState<readonly number[]>([]);
  const [driverDetails, setDriverDetails] = useState(drivers);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: number, driverID: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    navigate(`/driver-documentation/${driverID}`);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  }

  // Function to handle table pagination actions
  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5, textAlign: "center" }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    getData(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //API request for fetching all the driver documents
  const getData = async (page = 0) => {
    setLoading(true);
    const data: any = await getAllDrivers(page, rowsPerPage);
    if (data && data.message === "session_expired") {
      localStorage.clear();
      navigate("/");
    } else {
      setDriverDetails(data?.data);
      setTotalCount(data?.total_drivers);
    }
    setLoading(false);
  };

  useEffect(() => {
    //API request
    getData();
  }, []);
  const formatTime = (isoString: string) => {
    const date = new Date(isoString);

    // Format the date and time based on the device's locale and options
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Change this to false if you want a 24-hour format
    });
  };
  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>Drivers</Typography>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell className="cellContainer">Driver Name</TableCell>
                      <TableCell className="cellContainer">Phone</TableCell>
                      <TableCell className="cellContainer">Email</TableCell>
                      <TableCell className="cellContainer">Driver Type</TableCell>
                      <TableCell className="cellContainer">Shared Ride Enabled</TableCell>
                      <TableCell className="cellContainer">Rating</TableCell>
                      <TableCell className="cellContainer">City</TableCell>
                      <TableCell className="cellContainer">Status</TableCell>
                      <TableCell className="cellContainer">Rejected Ride Count</TableCell>
                      <TableCell className="cellContainer">Created at</TableCell>
                      <TableCell className="cellContainer">Updated at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {driverDetails.map((row: any) => {
                      const isItemSelected = isSelected(row._id);
                      return (
                        <TableRow
                          key={row._id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          hover
                          selected={isItemSelected}
                          onClick={(event) => handleClick(event, row._id, row._id)}
                        >
                          <TableCell className="textCapitalize">
                            {row.firstName} {row.lastName}
                          </TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell className="textCapitalize">{row.driver_type}</TableCell>
                          <TableCell>{row.accept_shared_rides ? "Yes" : "No"}</TableCell>
                          <TableCell>{row.rating.toFixed(2)}</TableCell>
                          <TableCell>{row?.address?.city}</TableCell>
                          <TableCell className="textCapitalize">
                            {row.vtcStatus ? row.vtcStatus : "pending"}
                          </TableCell>
                          <TableCell>{row.rejectedRideCount || 0}</TableCell>
                          <TableCell>{formatTime(row.createdAt)}</TableCell>
                          <TableCell>{formatTime(row.updatedAt)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={3}
                        count={totalCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DriverDocumentation;
