import { GET_COUNTRY_DATA } from "../actionTypes/actionTypes";
import { CountryType, RegionType } from "../types/earnings";

interface StateType {
  countryData: CountryType[];
  regions: RegionType[];
}

const INITIAL_STATE: StateType = {
  countryData: [],
  regions: [],
};

const masterReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRY_DATA:
      return Object.assign({}, state, { countryData: payload });
    default:
      return state;
  }
};

export default masterReducer;
