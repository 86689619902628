import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { getAllReferralPlans } from "../../apis/referralPlans";
import { getReferralPlans } from "../../actions/referralAction";
import ReferralDeleteModal from "../../components/ReferralDeleteModal";
import { getAllDiscounts } from "../../apis/discounts";
import { getDiscounts } from "../../actions/discountsAction";

const ReferralPlans = () => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [discounts, setDiscounts] = useState(state.discounts);
  const [referralDetails, setReferralDetails] = useState(state.referralPlans);
  const [openReferralDeleteModal, setReferralDeleteModal] = useState(false);
  const [referralId, setReferralId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Methods to handle the navigation to create and edit pages
  const navigateToCreate = () => navigate("/referral-plans/create");
  const navigateToEdit = (id: number) => {
    navigate(`/referral-plans/edit/${id}`);
  };

  // Method to handle the delete modal rendering
  const handleReferralDeleteOpen = (id: number) => {
    setReferralId(id);
    setReferralDeleteModal(true);
  };

  // Method to fetcha and update the referral data
  const getReferralData = async () => {
    setLoading(true);
    const data = await getAllReferralPlans();
    dispatch(getReferralPlans(data));
    setReferralDetails(data);
    await getDiscountData();
    setLoading(false);
  };

  // Method to fetch and update the dicounts data
  const getDiscountData = async () => {
    const data = await getAllDiscounts();
    dispatch(getDiscounts(data));
    setDiscounts(data);
  };

  // Method to filter out the discounts to display and render a user-friendly data
  const filteredDiscountData = (id: string) => {
    const data =
      discounts.length > 0 &&
      discounts?.filter((item: any) => {
        if (item._id === id) {
          return {
            promo_code: item.promo_code,
          };
        }
      });
    return data[0]?.promo_code || "--";
  };

  useEffect(() => {
    //fetch all the referrals data
    getReferralData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{ marginTop: "100px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>Referrals Plans</Typography>
                <Button variant="contained" onClick={navigateToCreate}>
                  Create Referral
                </Button>
                {/* Discount delete modal */}
                {openReferralDeleteModal && (
                  <ReferralDeleteModal
                    deleteId={referralId}
                    openState={openReferralDeleteModal}
                    dataset={referralDetails}
                    setData={setReferralDetails}
                    setOpenState={setReferralDeleteModal}
                    fetchUpdatedData={getReferralData}
                  />
                )}
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell className="cellContainer">Name</TableCell>
                      <TableCell className="cellContainer">Discount Promo Code</TableCell>
                      <TableCell className="cellContainer">Required Referral Count</TableCell>
                      <TableCell className="cellContainer">Country/Region</TableCell>
                      <TableCell className="cellContainer">Status</TableCell>
                      <TableCell className="cellContainer">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referralDetails &&
                      referralDetails.map((row: any) => (
                        <TableRow
                          key={row._id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell>{filteredDiscountData(row.discount_id)}</TableCell>
                          <TableCell>{row.referral_count_required}</TableCell>
                          <TableCell className="textCapitalize">
                            {row.country_code} / {row.region_code}
                          </TableCell>
                          <TableCell>{row.status}</TableCell>
                          <TableCell>
                            <EditIcon
                              style={{ cursor: "pointer", marginRight: "4px" }}
                              onClick={() => navigateToEdit(row._id)}
                            />
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleReferralDeleteOpen(row._id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralPlans;
