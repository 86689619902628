import { Box } from "@mui/material";

const ForgotPassword = () => {
  return (
    <Box>
      <h1>Forgot Password</h1>
    </Box>
  );
};

export default ForgotPassword;
