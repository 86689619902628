import axios from "axios";

const baseUrl = process.env.REACT_APP_ORDERS_SERVICE_BASE_URL;
export const setDashboardNumbers = async () => {
  let response;
  await axios
    .get(`${baseUrl}/api/v1/admin/get-rides-count?table=rides`, {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      console.log("data", res.data.data);
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
