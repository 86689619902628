import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const TypeXl = (props: any) => {
  const { setXlTypeDetails, xlTypeDetails } = props;
  const [latePrice, setLatePrice] = useState<number>();
  const [latePenalty, setLatePenalty] = useState<number>();

  const onHandleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    //console.log("data: ", { ...xlTypeDetails, [event.target.name]: event.target.value });
    if (event && event.target.name !== "price_per_minute" && event.target.name !== "penality") {
      setXlTypeDetails({
        ...xlTypeDetails,
        car_type: "GREEN",
        [event.target.name]: parseFloat(event.target.value),
      });
    } else {
      setXlTypeDetails({
        ...xlTypeDetails,
        late_price: {
          price_per_minute: latePrice || 0,
          penality: latePenalty || 0,
        },
      });
    }
  };

  useEffect(() => {
    const event = null;
    onHandleChange(event);
  }, [latePrice, latePenalty]);

  return (
    <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
      <Box>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
            Green Car Details
          </Typography>

          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              style={{
                padding: "20px",
              }}
            >
              <Grid container spacing={3}>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Price per KM"
                    label="Price per KM"
                    name="price_per_km"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.price_per_km}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Price per Min"
                    label="Price per Min"
                    name="price_per_min"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.price_per_min}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Late Price per Min"
                    label="Late Price per Min"
                    name="price_per_minute"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={(event) => {
                      setLatePrice(parseFloat(event.target.value));
                      onHandleChange(event);
                    }}
                    value={xlTypeDetails?.late_price?.price_per_minute}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Late Penalty"
                    label="Late Penalty"
                    name="penality"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={(event) => {
                      setLatePenalty(parseFloat(event.target.value));
                      onHandleChange(event);
                    }}
                    value={xlTypeDetails?.late_price?.penality}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Min Price"
                    label="Min Price"
                    name="min_price"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.min_price}
                  />
                </Grid>

                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Base Price"
                    label="Base Price"
                    name="base_price"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.base_price}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Share Ride Multiplier"
                    label="Share Ride Multiplier"
                    name="shared_ride_multiplier"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.shared_ride_multiplier}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Share Ride Min Price"
                    label="Share Ride Min Price"
                    name="share_ride_min_price"
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                    onChange={onHandleChange}
                    value={xlTypeDetails?.shared_ride_min_price}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default TypeXl;
