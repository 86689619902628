import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import EditIcon from "@mui/icons-material/Edit";
import { getConfigs } from "../../apis/earnings";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../types/earnings";
import "./style.css";

const Earnings = () => {
  const { configs } = useSelector((state: any) => state.earningsReducer);
  const [cityConfigs, setCityConfigs] = useState(configs);
  const [filteredCityConfigs, setFilteredCityConfigs] = useState(configs);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //function to handle navigation to edit page
  const navigateToEdit = (id: number) => {
    navigate(`/earnings-and-management/edit/${id}`);
  };

  // Fucntion to filter the configs according to the selected country and region
  const filteredConfigs = () => {
    setLoading(true);
    const filteredData = cityConfigs.filter(
      (item: any) =>
        item.country_code === country?.country_code && item.region_code === city?.region_code
    );
    if (filteredData.length) {
      setFilteredCityConfigs(filteredData);
    } else {
      setFilteredCityConfigs(cityConfigs);
    }
    setLoading(false);
  };

  // Function to fetch and update configs data
  const getCityConfigs = async () => {
    const data = await getConfigs();
    setCityConfigs(data);
    setFilteredCityConfigs(data);
  };

  // useEffect hook to reload the component when city is updated
  useEffect(() => {
    filteredConfigs();
  }, [city]);

  useEffect(() => {
    //API request
    getCityConfigs();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                  Earnings & Management
                </Typography>
              </div>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <CountryRegionSelect
                  country={country}
                  setCountry={setCountry}
                  city={city}
                  setCity={setCity}
                />
              </Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell align="center" className="cellContainer">
                        Driver Ride Percentage
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Normal Driver Comission
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Premium Driver Comission
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Community Driver Comission Within 6 Months
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Community Driver Comission After 6 Months
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Cancelled Ride Comission
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Cancelled Shared Ride Comission
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Ride Cancellation Charges
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Shared Ride Cancellation Charges
                      </TableCell>
                      <TableCell align="center" className="cellContainer">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "20px",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      filteredCityConfigs &&
                      filteredCityConfigs.map((row: any) => {
                        return (
                          <TableRow key={row._id}>
                            <TableCell align="center">{row.driver_ride_percentage}%</TableCell>
                            <TableCell align="center">{row.normal_driver_comission}%</TableCell>
                            <TableCell align="center">{row.premium_driver_comission}%</TableCell>
                            <TableCell align="center">
                              {row.community_driver_comission_within_six_months}%
                            </TableCell>
                            <TableCell align="center">
                              {row.community_driver_comission_after_six_months}%
                            </TableCell>
                            <TableCell align="center">{row.cancelled_ride_comission}%</TableCell>
                            <TableCell align="center">
                              {row.cancelled_shared_ride_comission}%
                            </TableCell>
                            <TableCell align="center">{row.ride_cancellation_charges}</TableCell>
                            <TableCell align="center">
                              {row.shared_ride_cancellation_charges}
                            </TableCell>
                            <TableCell align="center" style={{ cursor: "pointer" }}>
                              <EditIcon onClick={() => navigateToEdit(row._id)} />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Earnings;
