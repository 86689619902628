import {
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import "./style.css";
import { getUsersMetReferralPlans, sendPromoCode } from "../../apis/referralPlans";
import { getUserReferralTracking } from "../../actions/referralAction";

const ReferralTracker = () => {
  const dispatch = useDispatch();
  const { referralTrackData } = useSelector((state: any) => state.referralPlansReducer);

  // to handle the local state of the components
  const [referralDetails, setReferralDetails] = useState(referralTrackData);
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [open, setOpen] = useState(false);

  // Function to fetch all the users data met with the referral plan and update
  const getUsersData = async () => {
    setLoading(true);
    const data = await getUsersMetReferralPlans();
    dispatch(getUserReferralTracking(data));
    setReferralDetails(data);
    setLoading(false);
  };

  // methods to handle the snackbar rendering
  const openSnackBar = () => {
    setOpen(true);
    window.location.reload();
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };

  // Method to handle mark promo sent action
  const onSendPromo = async (userId: string) => {
    let res = await sendPromoCode(userId);
    if (res?.status) {
      setStatusMessage(res?.data);
      openSnackBar();
    }
  };

  useEffect(() => {
    //fetch all the users met with referrals plans
    getUsersData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{ marginTop: "100px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>
                  Users Met with Referrals Plans
                </Typography>
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell className="cellContainer">User Name</TableCell>
                      <TableCell className="cellContainer">Email</TableCell>
                      <TableCell className="cellContainer">Referral Plan</TableCell>
                      <TableCell className="cellContainer">Referred Count</TableCell>
                      <TableCell className="cellContainer">Promo Code</TableCell>
                      <TableCell className="cellContainer">Promo Sent</TableCell>
                      <TableCell align="center" className="cellContainer">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referralDetails &&
                      referralDetails.map((row: any) => (
                        <TableRow
                          key={row._id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ textTransform: "capitalize" }}
                          >
                            {row.first_name} {row.last_name}
                          </TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.referral_plan_name}</TableCell>
                          <TableCell>{row.referral_plan_count}</TableCell>
                          <TableCell>{row.promo_code}</TableCell>
                          <TableCell>{row.promo_sent ? "Yes" : "No"}</TableCell>
                          <TableCell align="center">
                            <Button
                              variant="contained"
                              disabled={!!row.promo_sent}
                              onClick={() => onSendPromo(row._id)}
                            >
                              Mark Promo Sent
                            </Button>
                            <Snackbar
                              anchorOrigin={{ vertical: "top", horizontal: "right" }}
                              open={open}
                              autoHideDuration={3000}
                              onClose={closeSnackBar}
                            >
                              <Alert
                                onClose={closeSnackBar}
                                severity="success"
                                sx={{ width: "100%", alignItems: "center" }}
                              >
                                {statusMessage}
                              </Alert>
                            </Snackbar>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralTracker;
