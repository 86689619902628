import { Box, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginError, UserData, LoginHelperText, LoginResponse } from "../types/user";
import { login } from "../apis/authentication";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const REGEX = {
  EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};
const sampleLoginError = {
  email: false,
  password: false,
};
const sampleObject = {
  email: "",
  password: "",
};
const loginHelperObject = {
  email: "Valid email is required",
  password: "Password is required",
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [loginError, setLoginError] = useState<LoginError>(sampleLoginError);
  const [loginHelperText] = useState<LoginHelperText>(loginHelperObject);
  const [loginField, setLoginField] = useState<UserData>(sampleObject);
  const navigate = useNavigate();

  const mainContainer: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "auto",
  };
  const innerSubContainer: React.CSSProperties = {
    margin: "50px",
    padding: "50px",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 10px 60px rgba(226, 236, 249, 0.5)",
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Method to handle the inputs
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginField({ ...loginField, [event.target.name]: event.target.value });
    checkLoginValidation(event.target.name, event.target.value);
  };

  // Method to validate the input fields
  const checkLoginValidation = (name: string, value: string) => {
    switch (name) {
      case "email":
        const isEmail = REGEX.EMAIL_REGEX.test(value);
        setLoginError({ ...loginError, [name]: !isEmail });
        break;
      case "password":
        const isPass = value.length > 3;
        setLoginError({ ...loginError, [name]: !isPass });
        break;
      default:
        break;
    }
  };

  // Method to handle the login action and API request
  const onHandleSubmit = async () => {
    if (loginField.password.length < 4) {
      setLoginError({ ...loginError, password: true });
      return;
    }

    if (!REGEX.EMAIL_REGEX.test(loginField.email)) {
      setLoginError({ ...loginError, email: true });
      return;
    }

    try {
      setLoading(true);
      const data: UserData = {
        email: loginField.email,
        password: loginField.password,
      };

      //login api request
      const response: LoginResponse | undefined = await login(data);
      if (response && response.status === 201) {
        localStorage.setItem("token", response.data.accessToken);
        navigate("/");
      }
      setLoading(false);
      return;
    } catch (error: any) {
      setLoading(false);
      setErrorText(error.data.message);
    }
  };

  return (
    <Box sx={mainContainer}>
      <Box sx={innerSubContainer}>
        <h1>Log In</h1>
        <TextField
          id="outlined-basic"
          placeholder="Email"
          name="email"
          variant="outlined"
          style={{ marginBottom: "10px" }}
          error={loginError.email}
          value={loginField.email}
          onChange={onHandleChange}
          onBlur={(event) => checkLoginValidation(event.target.name, event.target.value)}
        />
        {loginError.email && (
          <FormHelperText sx={{ color: "red" }}>{loginHelperText.email}</FormHelperText>
        )}
        <TextField
          id="outlined-basic"
          placeholder="Password"
          name="password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{ marginBottom: "10px" }}
          error={loginError.password}
          value={loginField.password}
          onChange={onHandleChange}
          onBlur={(event) => checkLoginValidation(event.target.name, event.target.value)}
        />
        {loginError.password && (
          <FormHelperText sx={{ color: "red" }}>{loginHelperText.password}</FormHelperText>
        )}
        <span
          style={{
            alignSelf: "end",
            textDecorationLine: "underline",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/forgot-password")}
        >
          Forgot Password
        </span>
        <Button
          variant="contained"
          onClick={() => {
            onHandleSubmit();
          }}
        >
          Log In
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
