import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DiscountDeleteModal from "../../components/DiscountDeleteModal";
import { getAllDiscounts } from "../../apis/discounts";
import { getDiscounts } from "../../actions/discountsAction";
import "./style.css";

const Discounts = () => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const [dicountsDetails, setDiscountsDetails] = useState(state.discounts);
  const [openDiscountDeleteModal, setDiscountDeleteModal] = useState(false);
  const [discountId, setDiscountId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const navigateToCreate = () => navigate("/discounts/create");

  const navigateToEdit = (id: number) => {
    navigate(`/discounts/edit/${id}`);
  };

  //handles the dicount delete modal
  const handleDiscountDeleteOpen = (id: number) => {
    setDiscountId(id);
    setDiscountDeleteModal(true);
  };

  //API requet to fetch all the discounts data
  const getData = async () => {
    setLoading(true);
    const data = await getAllDiscounts();
    dispatch(getDiscounts(data));
    setDiscountsDetails(data);
    setLoading(false);
  };

  const formatDate = (timestamp: string) => {
    var parsedTimestamp = new Date(timestamp);

    // Format it as "Dec 12, 2023"
    var options: any = { year: "numeric", month: "short", day: "numeric" };
    var formattedTimestamp = parsedTimestamp.toLocaleDateString("en-US", options);

    return formattedTimestamp;
  };

  useEffect(() => {
    //fetch all the discounts data
    getData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box style={{ width: "78%", marginTop: "2%" }}>
        <TopBar />
        <Box style={{ width: "96%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>Discounts</Typography>
                <Button variant="contained" onClick={navigateToCreate}>
                  Create Discount
                </Button>

                {/* Discount delete modal */}
                {openDiscountDeleteModal && (
                  <DiscountDeleteModal
                    deleteId={discountId}
                    openState={openDiscountDeleteModal}
                    dataset={dicountsDetails}
                    setData={setDiscountsDetails}
                    setOpenState={setDiscountDeleteModal}
                    fetchUpdatedData={getData}
                  />
                )}
              </div>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell className="cellContainer">Country/Region Name</TableCell>
                      <TableCell className="cellContainer">Type</TableCell>
                      <TableCell className="cellContainer">Percentage</TableCell>
                      <TableCell className="cellContainer">Min Order Amount</TableCell>
                      <TableCell className="cellContainer">Max Order Amount</TableCell>
                      <TableCell className="cellContainer">Min Discount Amount</TableCell>
                      <TableCell className="cellContainer">Max Discount Amount</TableCell>
                      <TableCell className="cellContainer">Min Rides Required</TableCell>
                      <TableCell className="cellContainer">Promo Code</TableCell>
                      <TableCell className="cellContainer">Applied Count</TableCell>
                      <TableCell className="cellContainer">Users Allowed</TableCell>
                      <TableCell className="cellContainer">Allowed for Pro User</TableCell>
                      <TableCell className="cellContainer">Expiry Date</TableCell>
                      <TableCell className="cellContainer">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dicountsDetails &&
                      dicountsDetails.map((row: any) => (
                        <TableRow
                          key={row._id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row" className="textCapitalize">
                            {row.country_code}/{row.region_code}
                          </TableCell>
                          <TableCell className="textCapitalize">{row.type}</TableCell>
                          <TableCell>{row.percentage}%</TableCell>
                          <TableCell>{row.min_order_amount}</TableCell>
                          <TableCell>{row.max_order_amount}</TableCell>
                          <TableCell>{row.min_discount_amount}</TableCell>
                          <TableCell>{row.max_discount_amount}</TableCell>
                          <TableCell>{row.min_rides_required}</TableCell>
                          <TableCell>{row.promo_code}</TableCell>
                          <TableCell>{row.promo_used_count ? row.promo_used_count : 0}</TableCell>
                          <TableCell>{row.users_allowed ? row.users_allowed : "--"}</TableCell>
                          <TableCell className="textCapitalize">
                            {row.is_pro_users_allowed}
                          </TableCell>
                          <TableCell>{formatDate(row.expiry_date)}</TableCell>
                          <TableCell>
                            <EditIcon
                              style={{ cursor: "pointer", marginRight: "4px" }}
                              onClick={() => navigateToEdit(row._id)}
                            />
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleDiscountDeleteOpen(row._id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Discounts;
