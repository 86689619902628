import {
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Paper,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import SidePanel from "../../components/SidePanel";
import CircularProgress from "@mui/material/CircularProgress";
import TopBar from "../../components/TopBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../../components/DeleteModal";
import { getAllSurgePricing } from "../../apis/surgePricing";
import { useDispatch } from "react-redux";
import { getSurgePricing } from "../../actions/surgePricingAction";
import {
  addSurgeEventButtonContainer,
  loader,
  rootContainer,
  surgeEvent,
  tableContainer,
} from "./styles/ListSurgeEvents.styles";

const SurgeEvents = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const navigateToCreate = () => navigate("/surge-events/create");
  const [eventId, setEventId] = useState<string | null>(null);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [eventDetails, setEventDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Function to handle the navigation to edit page
  const navigateToEdit = (id: string) => {
    navigate(`/surge-events/edit/${id}`);
  };

  // Function to format a date in the user-friednly format
  const formatDate = (timestamp: string) => {
    var parsedTimestamp = new Date(timestamp);

    // Format it as "Dec 12, 2023"
    var options: any = { year: "numeric", month: "short", day: "numeric" };
    var formattedTimestamp = parsedTimestamp.toLocaleDateString("en-US", options);

    return formattedTimestamp;
  };

  // Method to display time in user-friendly format
  const convertToHHMM = (minutes: number) => {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    var hoursStr = hours < 10 ? "0" + hours : hours;
    var minutesStr = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;

    return hoursStr + ":" + minutesStr;
  };

  // Method to display days in user-friendly format
  const convertToDaysOfWeek = (arr: number[]) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const selectedDays = arr.map((index) => daysOfWeek[index]);
    const result = selectedDays.join(", ");

    return `[${result}]`;
  };

  const convertToMonthDayString = (month: number, day: number) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthName = months[month - 1];
    const formattedDate = `${monthName}, ${day}`;

    return formattedDate;
  };

  // Function to handle delete modal redenring
  const handleDeleteOpen = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    setEventId(id);
    setDeleteModal(true);
  };

  // Function to fetch and update the data
  const getData = async () => {
    setLoading(true);
    const data = await getAllSurgePricing();
    dispatch(getSurgePricing(data));
    setEventDetails(data);
    setLoading(false);
  };

  useEffect(() => {
    //fetch all the discounts data
    getData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={rootContainer}>
          <Box>
            <div style={addSurgeEventButtonContainer}>
              <Typography style={surgeEvent}>Surge Events</Typography>
              <Button variant="contained" onClick={navigateToCreate}>
                Add Surge Event
              </Button>
              {openDeleteModal && (
                <DeleteModal
                  id={eventId}
                  openModal={openDeleteModal}
                  setOpenModal={setDeleteModal}
                  deleteMessage="Do you want to delete this surge  event?"
                  fetchUpdatedEvent={getData}
                />
              )}
            </div>
            {!loading ? (
              <TableContainer component={Paper} sx={tableContainer}>
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                    <TableRow>
                      <TableCell className="cellContainer">Event Name</TableCell>
                      <TableCell className="cellContainer">Event Start Date</TableCell>
                      <TableCell className="cellContainer">Event End Date</TableCell>
                      <TableCell className="cellContainer">Hours Range</TableCell>
                      <TableCell className="cellContainer">Frequency</TableCell>
                      <TableCell className="cellContainer">Week Days</TableCell>
                      <TableCell className="cellContainer">Month Day</TableCell>
                      <TableCell className="cellContainer">Year Day</TableCell>
                      <TableCell className="cellContainer">Surge Multiplier</TableCell>
                      <TableCell className="cellContainer">Country/Region</TableCell>
                      <TableCell className="cellContainer">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {eventDetails &&
                      eventDetails.map((row: any) => {
                        return (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              cursor: "pointer",
                            }}
                          >
                            <TableCell align="center" className="textCapitalize">
                              {row.event_name}
                            </TableCell>
                            <TableCell align="center">
                              {formatDate(row.event_start_date_time)}
                            </TableCell>
                            <TableCell align="center">
                              {formatDate(row.event_end_date_time)}
                            </TableCell>
                            <TableCell align="center">
                              {convertToHHMM(row.hours_range.start)} -{" "}
                              {convertToHHMM(row.hours_range.end)}
                            </TableCell>
                            <TableCell align="center">{row.frequency}</TableCell>
                            <TableCell align="center">
                              {row.frequency === "WEEKLY"
                                ? convertToDaysOfWeek(row.week_days)
                                : "-"}
                            </TableCell>
                            <TableCell align="center">
                              {row.frequency === "MONTHLY" ? row.month_day : "-"}
                            </TableCell>
                            <TableCell align="center">
                              {row.frequency === "YEARLY"
                                ? convertToMonthDayString(row.year_day.month, row.year_day.day)
                                : "-"}
                            </TableCell>
                            <TableCell align="center">{row.multiplier}</TableCell>
                            <TableCell align="center" className="textCapitalize">
                              {row.country_code}/{row.region_code}
                            </TableCell>
                            <TableCell align="center">
                              <EditIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  navigateToEdit(row._id);
                                }}
                              />{" "}
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  handleDeleteOpen(event, row._id);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box sx={loader}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SurgeEvents;
