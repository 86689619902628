import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText, Typography, Paper, Box } from "@mui/material";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";

interface Client {
  clientId: string;
  clientRole: string;
  ownerName?: string;
  carType?: string;
  connectedTime?: string; // New field for time
}
interface Event {
  event: string;
  client: Client;
  error: string;
  connections: string;
}
const WebSocketComponent: React.FC = () => {
  const [connectedClients, setConnectedClients] = useState<Client[]>([]);
  const [disconnectedClients, setDisconnectedClients] = useState<Client[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const setConnectedClientsWithTime = (connections: Client[]) => {
    const updatedClients = connections?.map((client) => ({
      ...client,
      connectedTime: new Date().toLocaleTimeString(), // Add current time in the device timezone
    }));

    setConnectedClients(updatedClients);
  };

  const setDisconnectedClientsWithTime = (connection: Client) => {
    const updatedClient = {
      ...connection,
      connectedTime: new Date().toLocaleTimeString(), // Add current time in the device timezone
    };
    setDisconnectedClients((prevClients) => [...prevClients, updatedClient]);
  };

  useEffect(() => {
    const API_KEY = process.env.REACT_APP_API_KEY;
    const socketBaseUrl = process.env.REACT_APP_WEB_SOCKET_SERVICE_BASE_URL;
    console.log("API Key: " + API_KEY);
    const socket = new WebSocket(`${socketBaseUrl}?api_key=${API_KEY}`);

    const interval = setInterval(() => {
      window.location.reload();
    }, 60000); // Change 1 min to the interval time in milliseconds

    socket.onopen = () => {
      console.log("WebSocket connection opened");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data) as Event;
      console.log("Message received", data);
      switch (data.event) {
        case "connect":
          console.log("WebSocket connection", data);
          setConnectedClientsWithTime(JSON.parse(data.connections));
          break;
        case "disconnect":
          console.log("WebSocket connection", data);
          setDisconnectedClientsWithTime(data.client);
          setConnectedClientsWithTime(JSON.parse(data.connections));
          break;
        case "error":
          setErrors((prevErrors) => [
            ...prevErrors,
            `${data.error} - ${new Date().toLocaleTimeString()}`,
          ]);
          setConnectedClientsWithTime(JSON.parse(data.connections));
          break;
        default:
          break;
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };
    // Cleanup interval on component unmount
    return () => {
      clearInterval(interval);
      socket.close();
    };
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Paper elevation={3} style={{ margin: "20px", padding: "20px" }}>
            <Typography variant="h5">Connected Clients</Typography>
            <List>
              {connectedClients.map((client) => (
                <ListItem key={client.clientId}>
                  <ListItemText
                    primary={`ID: ${client.clientId} | Role: ${client.clientRole} | Connected at: ${client.connectedTime}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
          <Paper elevation={3} style={{ margin: "20px", padding: "20px" }}>
            <Typography variant="h5">Disconnected Clients</Typography>
            <List>
              {disconnectedClients.map((client) => (
                <ListItem key={client.clientId}>
                  <ListItemText
                    primary={`ID: ${client.clientId} | Role: ${client.clientRole} | Disconnected at: ${client.connectedTime}`}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>

          <Paper elevation={3} style={{ margin: "20px", padding: "20px" }}>
            <Typography variant="h5">Errors</Typography>
            <List>
              {errors.map((error, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${error}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default WebSocketComponent;
