import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import { CircularProgress } from "@mui/material";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getPricingById, updatePricing } from "../../apis/pricing";

const EditPricing = () => {
  const { indice, indices } = useSelector((state: any) => state.pricingReducer);
  const dispatch = useDispatch();
  const [pricingData, setPricingData] = useState({ index: -1, data: indice, allPrices: indices });
  const navigate = useNavigate();
  let params = useParams();
  const [searchParams] = useSearchParams();
  const pricingId: string | undefined = params?.id;
  const carType: string | null = searchParams.get("type");
  const [loading, setLoading] = useState(false);

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.id === "late-price-per-min") {
      const latePriceObj = {
        ...pricingData.data.late_price,
        [event.target.name]: event.target.value,
      };
      setPricingData((prevPriceData) => ({
        ...prevPriceData,
        data: {
          ...prevPriceData.data,
          late_price: latePriceObj, // set the specific key to the desired value
        },
      }));
    } else if (event.target.id === "late-penalty") {
      const latePenaltyObj = {
        ...pricingData.data.late_price,
        [event.target.name]: event.target.value,
      };
      setPricingData((prevPriceData) => ({
        ...prevPriceData,
        data: {
          ...prevPriceData.data,
          late_price: latePenaltyObj, // set the specific key to the desired value
        },
      }));
    } else {
      setPricingData((prevPriceData) => ({
        ...prevPriceData,
        data: {
          ...prevPriceData.data,
          [event.target.name]: event.target.value, // set the specific key to the desired value
        },
      }));
    }
  };

  const goToBack = () => navigate("/pricing");

  const updatePricingData = async () => {
    const id = pricingId !== undefined ? pricingId : "";

    const indexToUpdate = pricingData.index;
    if (indexToUpdate !== -1) {
      pricingData.allPrices.price_list[indexToUpdate] = {
        ...pricingData.allPrices.price_list[indexToUpdate],
        ...pricingData.data,
      };
    }

    if (id) {
      //delete Object.assign(pricingDetails, { ["price_list"]: pricingDetails["indice"] })["indice"];
      const response: any = await updatePricing(id, pricingData.allPrices);
      if (response.status) {
        goToBack();
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    const id = pricingId !== undefined ? pricingId : "";
    if (id) {
      const data: any = await getPricingById(id);
      if (data) {
        const index = data?.price_list.findIndex((item: any) => item.car_type === carType);
        setPricingData({ index, data: data?.price_list[index], allPrices: data });
      } else {
        goToBack();
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
                  Update Pricing Details
                </Typography>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Box
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Car Type"
                          label="Car Type"
                          name="car_type"
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          value={pricingData.data?.car_type}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Price per KM"
                          label="Price per KM"
                          name="price_per_km"
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          value={pricingData.data?.price_per_km}
                          onChange={onHandleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Price per Min"
                          label="Price per Min"
                          name="price_per_min"
                          variant="outlined"
                          value={pricingData.data?.price_per_min}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="late-price-per-min"
                          size="small"
                          placeholder="Late Price"
                          label="Late Price per Min"
                          name="price_per_minute"
                          variant="outlined"
                          value={pricingData.data?.late_price?.price_per_minute}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="late-penalty"
                          size="small"
                          placeholder="Late Price"
                          label="Late Penalty"
                          name="penality"
                          variant="outlined"
                          value={pricingData.data?.late_price?.penality}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Normal Ride Min Price"
                          label="Normal Ride Min Price"
                          name="min_price"
                          variant="outlined"
                          value={pricingData.data?.min_price}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>

                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Base Price"
                          label="Base Price"
                          name="base_price"
                          variant="outlined"
                          value={pricingData.data?.base_price}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Shared Ride Multiplier"
                          label="Shared Ride Multiplier"
                          name="shared_ride_multiplier"
                          variant="outlined"
                          value={pricingData.data?.shared_ride_multiplier}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Shared Ride Min Price"
                          label="Shared Ride Min Price"
                          name="shared_ride_min_price"
                          variant="outlined"
                          value={pricingData.data?.shared_ride_min_price}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItem: "center",
                    }}
                  >
                    <Button
                      style={{ marginRight: "4px", backgroundColor: "gray" }}
                      variant="contained"
                      onClick={goToBack}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={updatePricingData}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditPricing;
