import { GET_DASHBOARD_NUMBERS } from "../actionTypes/actionTypes";

const getDashboardNumbers = (payload: any) => {
  return {
    type: GET_DASHBOARD_NUMBERS,
    payload: payload,
  };
};

export { getDashboardNumbers };
