import { GET_ALL_PRICINGS } from "../actionTypes/actionTypes";
import { PricingDetails, Indice } from "../types/pricing";

interface StateType {
  indices: PricingDetails[];
  indice: Indice;
}

const INITIAL_STATE: StateType = {
  indices: [],
  indice: {
    car_type: "",
    price_per_km: 0,
    price_per_min: 0,
    min_price: 0,
    base_price: 0,
    late_price: {
      price_per_minute: 0,
      penality: 0,
    },
  },
};

const pricingReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PRICINGS:
      return Object.assign({}, state, { indices: payload });
    default:
      return state;
  }
};

export default pricingReducer;
