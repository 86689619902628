import { GET_ALL_DISCOUNTS } from "../actionTypes/actionTypes";

const getSurgePricing = (payload: any) => {
  return {
    type: GET_ALL_DISCOUNTS,
    payload: payload,
  };
};

export { getSurgePricing };
