import { Box } from "@mui/material";

const Register = () => {
  return (
    <Box>
      <h1>Create New Account</h1>
    </Box>
  );
};

export default Register;
