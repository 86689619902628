import { GET_ALL_PRICINGS } from "../actionTypes/actionTypes";

const getAllPricings = (payload: any) => {
  return {
    type: GET_ALL_PRICINGS,
    payload: payload,
  };
};

export { getAllPricings };
