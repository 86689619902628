import { GET_DASHBOARD_NUMBERS } from "../actionTypes/actionTypes";

interface StateType {
  dashboardNumbers: {
    total_rides_count: number;
    active_rides_count: number;
    completed_rides_count: number;
    cancelled_rides_by_rider_count: number;
    cancelled_rides_by_driver_count: number;
    rides_without_driver_match_count: number;
  };
}

const INITIAL_STATE: StateType = {
  dashboardNumbers: {
    total_rides_count: 0,
    active_rides_count: 0,
    completed_rides_count: 0,
    cancelled_rides_by_rider_count: 0,
    cancelled_rides_by_driver_count: 0,
    rides_without_driver_match_count: 0,
  },
};

const dashboardReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DASHBOARD_NUMBERS:
      return Object.assign({}, state, { dashboardNumbers: payload });
    default:
      return state;
  }
};

export default dashboardReducer;
