import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import { SurgeEvent } from "../../types/surgeEvents";
import { getSurgePricing, updateSurgePricingEvent } from "../../apis/surgePricing";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, InputLabel, MenuItem, Select, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../types/earnings";
import {
  backButtonContainer,
  gridContainer,
  gridItem,
  rootContainer,
  updateButtonContainer,
  updateSurgeEvent,
} from "./styles/EditSurgeEvents.style";

const initialSurgeEventData = {
  event_name: "",
  event_description: "",
  start_time: "",
  end_time: "",
  frequency: "",
  week_days: [0],
  month_day: 0,
  year_month: 0,
  year_day: 0,
  country: "",
  multiplier: 1,
};

const EditSurgeEvents = () => {
  const [eventData, setEventData] = useState<any>(initialSurgeEventData);
  const [eventStartDate, setEventStartDate] = useState<any>(dayjs("2022-04-17"));
  const [eventEndDate, setEventEndDate] = useState<any>(dayjs("2022-04-17"));
  const [weekDays, setWeekDays] = useState([0]);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const [loading, setLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const navigate = useNavigate();
  let params = useParams();
  const eventId: string | undefined = params?.id;

  // Method to handle and update the inputs
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "week_days") {
      let value = event.target.value;
      let weekDaysAsIntegerArray = convertStringToArray(value);
      setWeekDays(value !== "" ? weekDaysAsIntegerArray : [0]);
    }
    setEventData({ ...eventData, [event.target.name]: event.target.value });
  };

  const convertStringToArray = (inputString: string) => {
    const arrayOfIntegers = inputString.split(",").map((num) => parseInt(num.trim(), 10));
    return arrayOfIntegers;
  };

  const convertToHHMM = (minutes: number) => {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    var hoursStr = hours < 10 ? "0" + hours : hours;
    var minutesStr = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;

    return hoursStr + ":" + minutesStr;
  };

  const onStartDateChange = (value: any) => {
    setEventStartDate(value.toISOString());
  };

  const onEndDateChange = (value: any) => {
    setEventEndDate(value.toISOString());
  };

  // Methos to update the event with the latest inputs using API request
  const updateEvent = async () => {
    let payload: SurgeEvent = {
      event_name: eventData.event_name,
      event_start_date_time: eventStartDate,
      event_end_date_time: eventEndDate,
      event_description: eventData.event_description,
      hours_range: {
        start: eventData.start_time,
        end: eventData.end_time,
      },
      frequency: eventData.frequency,
      week_days: weekDays,
      month_day: Number(eventData.month_day),
      year_day: {
        month: Number(eventData.year_month),
        day: Number(eventData.year_day),
      },
      country_code: country?.country_code || "",
      region_code: city?.region_code || "",
      multiplier: Number(eventData.multiplier),
    };
    let response = await updateSurgePricingEvent(payload, eventId);
    if (response.status) {
      setEventData(initialSurgeEventData);
      navigate("/surge-events");
    }
  };

  useEffect(() => {
    getSurgePricingEvent();
  }, []);

  //Method to fetch and update the surge pricing events data
  const getSurgePricingEvent = async () => {
    setLoading(true);
    const id = eventId ? eventId : "";
    let data: any = await getSurgePricing(id);
    const localEventData = {
      event_name: data?.event_name,
      event_description: data?.event_description,
      start_time: convertToHHMM(data?.hours_range.start),
      end_time: convertToHHMM(data?.hours_range.end),
      frequency: data?.frequency,
      week_days: data?.week_days.toString(),
      month_day: data?.month_day,
      year_month: data?.year_day.month,
      year_day: data?.year_day.day,
      country: data?.country,
      multiplier: data?.multiplier,
    };
    setEventData(localEventData);
    setEventStartDate(dayjs(data?.event_start_date_time));
    setEventEndDate(dayjs(data?.event_end_date_time));
    setCountry(data?.country_code);
    setCity(data?.region_code);
    setIsDataFetched(true);
    setLoading(false);
  };

  // Method to handle the back navigation
  const goBack = () => navigate("/surge-events");

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={rootContainer}>
          <Box>
            <Typography style={updateSurgeEvent}>Update Event</Typography>
            <Box sx={backButtonContainer}>
              <Button variant="contained" onClick={goBack}>
                Back
              </Button>
            </Box>
            {!loading ? (
              <Box sx={gridContainer}>
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      placeholder="Event name"
                      value={eventData.event_name}
                      label="Event name"
                      name="event_name"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={onStartDateChange}
                        label="Event start date"
                        value={eventStartDate}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          onChange={onEndDateChange}
                          label="Event end date"
                          value={eventEndDate}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="Description"
                      label="Description"
                      value={eventData.event_description}
                      name="event_description"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="Start time (HH:MM)"
                      value={eventData.start_time}
                      label="Start time"
                      name="start_time"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="End time (HH:MM)"
                      label="End time"
                      value={eventData.end_time}
                      name="end_time"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label" size="small">
                        Frequency
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        size="small"
                        value={eventData.frequency}
                        label="Frequency"
                        onChange={(event) => {
                          setEventData({ ...eventData, frequency: event.target.value as string });
                        }}
                      >
                        <MenuItem value={"ONCE"}>Once</MenuItem>
                        <MenuItem value={"DAILY"}>Daily</MenuItem>
                        <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                        <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                        <MenuItem value={"YEARLY"}>Yearly</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {eventData.frequency === "WEEKLY" && (
                    <Grid item xs={3}>
                      <TextField
                        id="standard-basic"
                        size="small"
                        placeholder="Days of the week (0,1,2)"
                        label="Days of the week"
                        name="week_days"
                        value={eventData.week_days}
                        variant="outlined"
                        style={gridItem}
                        onChange={onHandleChange}
                        disabled={eventData.frequency !== "WEEKLY"}
                      />
                    </Grid>
                  )}
                  {eventData.frequency === "YEARLY" && (
                    <Grid item xs={3}>
                      <TextField
                        id="standard-basic"
                        size="small"
                        type="number"
                        placeholder="Year Month (1-12)"
                        label="Year Month"
                        value={eventData.year_month}
                        name="year_month"
                        variant="outlined"
                        style={gridItem}
                        onChange={onHandleChange}
                        disabled={eventData.frequency !== "YEARLY"}
                      />
                    </Grid>
                  )}
                  {eventData.frequency === "YEARLY" && (
                    <Grid item xs={3}>
                      <TextField
                        id="standard-basic"
                        size="small"
                        type="number"
                        placeholder="Year Day (1-31)"
                        label="Year Day"
                        name="year_day"
                        value={eventData.year_day}
                        variant="outlined"
                        style={gridItem}
                        onChange={onHandleChange}
                        disabled={eventData.frequency !== "YEARLY"}
                      />
                    </Grid>
                  )}
                  {eventData.frequency === "MONTHLY" && (
                    <Grid item xs={3}>
                      <TextField
                        id="standard-basic"
                        size="small"
                        type="number"
                        placeholder="Month Day (1-31)"
                        label="Month Day"
                        value={eventData.month_day}
                        name="month_day"
                        variant="outlined"
                        style={gridItem}
                        onChange={onHandleChange}
                        disabled={eventData.frequency !== "MONTHLY"}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="number"
                      placeholder="Surge Multiplier"
                      value={eventData.multiplier}
                      label="Surge Multiplier"
                      name="multiplier"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                    />
                  </Grid>
                  {isDataFetched && (
                    <Grid item>
                      <CountryRegionSelect
                        country={country}
                        setCountry={setCountry}
                        city={city}
                        setCity={setCity}
                      />
                    </Grid>
                  )}
                </Grid>
                <Box sx={updateButtonContainer}>
                  <Button variant="contained" onClick={updateEvent}>
                    Update
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditSurgeEvents;
