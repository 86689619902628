import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { deleteSurgePricingEvent } from "../apis/surgePricing";

const closeIconStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "end",
  marginBottom: 1,
};

const DeleteModal = (props: any) => {
  const { id, openModal, setOpenModal, deleteMessage, fetchUpdatedEvent } = props;

  const handleClose = () => setOpenModal(false);

  const deletePricing = async () => {
    let response = await deleteSurgePricingEvent(id);
    if (response.status) {
      fetchUpdatedEvent();
      handleClose();
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box>
            <Box sx={closeIconStyle}>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
            </Box>
            <Box style={{ marginBottom: "30px" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Delete
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              paddingBottom: "20px",
            }}
          >
            {deleteMessage}
          </Box>

          <Box sx={closeIconStyle}>
            <Button variant="contained" onClick={deletePricing}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModal;
