import CSS from "csstype";

export const rootContainer: CSS.Properties = {
  width: "100%",
  padding: "20px",
  marginTop: "5%",
};

export const updateSurgeEvent: CSS.Properties = {
  fontWeight: 700,
  fontSize: "18px",
  marginBottom: "20px",
};

export const backButtonContainer: CSS.Properties = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  marginBottom: "20px",
};

export const gridContainer = {
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 5,
};

export const gridItem: CSS.Properties = {
  width: "100%",
};

export const updateButtonContainer = {
  display: "flex",
  justifyContent: "end",
  alignItem: "center",
};
