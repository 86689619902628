import {
  CREATE_DISCOUNT,
  GET_ALL_DISCOUNTS,
  GET_DISCOUNT,
  UPDATE_DISCOUNT,
} from "../actionTypes/actionTypes";
import { DiscountData } from "../types/discounts";

interface StateType {
  discounts: DiscountData[];
  discount: DiscountData;
}

const INITIAL_STATE: StateType = {
  discounts: [],
  discount: {
    country_code: "",
    region_code: "",
    type: "",
    is_archived: false,
    percentage: 0,
    min_order_amount: 0,
    max_order_amount: 0,
    min_discount_amount: 0,
    max_discount_amount: 0,
    min_rides_required: 0,
    promo_code: "",
    expiry_date: "",
    created_at: "",
    updated_at: "",
    promo_used_count: 0,
    users_allowed: [],
  },
};

const discountReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DISCOUNTS:
      return Object.assign({}, state, { discounts: payload });
    case GET_DISCOUNT:
      return Object.assign({}, state, { discount: payload });
    case CREATE_DISCOUNT:
      return Object.assign({}, state, { discount: payload });
    case UPDATE_DISCOUNT:
      return Object.assign({}, state, { discount: payload });
    default:
      return state;
  }
};

export default discountReducer;
