import {
  GET_ALL_DISCOUNTS,
  CREATE_DISCOUNT,
  UPDATE_DISCOUNT,
  DELETE_DISCOUNT,
  GET_DISCOUNT,
} from "../actionTypes/actionTypes";

const getDiscounts = (payload: any) => {
  return {
    type: GET_ALL_DISCOUNTS,
    payload: payload,
  };
};

const getDiscountById = (payload: any) => {
  return {
    type: GET_DISCOUNT,
    payload: payload,
  };
};

const createDiscount = (payload: any) => {
  return {
    type: CREATE_DISCOUNT,
    payload: payload,
  };
};

const updateDiscount = (payload: any) => {
  return {
    type: UPDATE_DISCOUNT,
    payload: payload,
  };
};

const deleteDiscount = (payload: any) => {
  return {
    type: DELETE_DISCOUNT,
    payload: payload,
  };
};

export { getDiscounts, createDiscount, updateDiscount, deleteDiscount };
