import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Drawer, List, ListItem } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DiscountIcon from "@mui/icons-material/Discount";
import DocumentIcon from "@mui/icons-material/DocumentScanner";
import PricingIcon from "@mui/icons-material/Payment";
import EarningsIcon from "@mui/icons-material/Payments";
import SurgeEventsIcon from "@mui/icons-material/Event";
import ReferralIcon from "@mui/icons-material/LocalOffer";
import ReferralTrackIcon from "@mui/icons-material/Timeline";
import Logo from "../assets/images/logo.png";
import { jwtDecode } from "jwt-decode";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  flexDirection: "row",
}));

const drawerHeader: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  margin: "5px 10px 20px 10px",
  padding: "5px",
  backgroundColor: "#d3d3d3",
};

const list: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100vh",
};

const listItem: React.CSSProperties = {
  cursor: "pointer",
  color: "#000000",
};

const drawerStyle: React.CSSProperties = {
  width: "20%",
  backgroundColor: "#FFFFFF",
  height: "100vh",
  borderRight: "none",
};

const drawerStyleResponsive: React.CSSProperties = {
  width: "80%",
  backgroundColor: "#F7FAFC",
  height: "100vh",
  borderRight: "none",
};

const logoutButtonStyleResponsive: React.CSSProperties = {
  fontSize: 16,
  color: "#000000",
  marginLeft: "10%",
  marginBottom: 25,
  display: "flex",
  justifyContent: "start",
  cursor: "pointer",
  alignItems: "center",
};

const adminTabList = [
  {
    id: 0,
    route: "",
    title: "Dashboard",
    icon: <DashboardIcon />,
    icon2: <DashboardIcon />,
  },
  {
    id: 1,
    route: "discounts",
    title: "Discounts",
    icon: <DiscountIcon />,
    icon2: <DiscountIcon />,
  },
  {
    id: 2,
    route: "referral-plans",
    title: "Referral Plans",
    icon: <ReferralIcon />,
    icon2: <ReferralIcon />,
  },
  {
    id: 3,
    route: "referral-tracking",
    title: "Referral Tracking",
    icon: <ReferralTrackIcon />,
    icon2: <ReferralTrackIcon />,
  },
  {
    id: 4,
    route: "driver-documentation",
    title: "Driver Documentation",
    icon: <DocumentIcon />,
    icon2: <DocumentIcon />,
  },
  {
    id: 5,
    route: "pricing",
    title: "Pricing",
    icon: <PricingIcon />,
    icon2: <PricingIcon />,
  },
  {
    id: 6,
    route: "surge-events",
    title: "Surge Events",
    icon: <SurgeEventsIcon />,
    icon2: <SurgeEventsIcon />,
  },
  {
    id: 7,
    route: "earnings-and-management",
    title: "Earnings & Management",
    icon: <EarningsIcon />,
    icon2: <EarningsIcon />,
  },
  {
    id: 6,
    route: "websocket-ui",
    title: "Web Socket UI",
    icon: <DashboardIcon />,
    icon2: <DashboardIcon />,
  },
];

const operatorTabList = [
  {
    id: 0,
    route: "",
    title: "Dashboard",
    icon: <DashboardIcon />,
    icon2: <DashboardIcon />,
  },
  {
    id: 1,
    route: "discounts",
    title: "Discounts",
    icon: <DiscountIcon />,
    icon2: <DiscountIcon />,
  },
  {
    id: 4,
    route: "driver-documentation",
    title: "Driver Documentation",
    icon: <DocumentIcon />,
    icon2: <DocumentIcon />,
  },
];

const SidePanel = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [tabList, setTabList] = useState<any>(null);
  const token = localStorage.getItem("token");
  let location = useLocation();
  const navigate = useNavigate();
  const urlHash = location.pathname === "/" ? "/" : location.pathname;
  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.role === "admin") {
        setTabList(adminTabList);
      } else if (decoded.role === "operator") {
        setTabList(operatorTabList);
      }
    }
  }, [token]);

  const handleCloseSidebar = () => {
    onClose();
  };

  return (
    <div>
      <Drawer
        variant="permanent"
        anchor="left"
        open={isOpen}
        PaperProps={{
          style: isMobile ? drawerStyleResponsive : drawerStyle,
        }}
      >
        <DrawerHeader style={drawerHeader}>
          <img src={Logo} alt="logo" width={200} height={50} />
        </DrawerHeader>

        <List style={list}>
          {tabList?.map((data: any, index: number) => (
            <div
              key={index}
              style={{
                background: urlHash === `/${data.route}` ? "#1677BC" : "#FFFFFF",
                margin: "3%",
                borderRadius: "5px",
              }}
            >
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: `/${data.route}`,
                }}
              >
                <ListItem style={listItem}>
                  <span
                    style={{
                      fontSize: 16,
                      color: urlHash === `/${data.route}` ? "#FFFFFF" : "#000000",
                      marginLeft: 8,
                      marginTop: 4,
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {urlHash === `/${data.route}` ? data.icon : data.icon} &nbsp; &nbsp;
                    {data.title}
                  </span>
                </ListItem>
              </Link>
            </div>
          ))}
        </List>
        {isMobile ? (
          <Box>
            <span style={logoutButtonStyleResponsive} onClick={handleCloseSidebar}>
              <CloseIcon /> &nbsp; &nbsp; Close
            </span>
          </Box>
        ) : null}
      </Drawer>
    </div>
  );
};

export default SidePanel;
