import axios from "axios";
import { LoginResponse, UserData } from "../types/user";

const baseUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;

export const login = async (body: UserData) => {
  let response: LoginResponse | undefined;
  await axios
    .post(`${baseUrl}/auth/v1/admin/login`, body)
    .then((res) => {
      response = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      console.log("Error:", e);
      response = undefined;
    });

  return response;
};
