export const GET_DASHBOARD_NUMBERS = "GET_DASHBOARD_NUMBERS";
export const GET_ALL_DISCOUNTS = "GET_ALL_DISCOUNTS";
export const GET_DISCOUNT = "GET_DISCOUNT";
export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const DELETE_DISCOUNT = "DELETE_DISCOUNT";

export const GET_ALL_REFERRAL_PLANS = "GET_ALL_REFERRAL_PLANS";
export const GET_REFERRAL_PLAN = "GET_REFERRAL_PLAN";
export const CREATE_REFERRAL_PLAN = "CREATE_REFERRAL_PLAN";
export const UPDATE_REFERRAL_PLAN = "UPDATE_REFERRAL_PLAN";
export const DELETE_REFERRAL_PLAN = "DELETE_REFERRAL_PLAN";

export const GET_ALL_DRIVERS = "GET_ALL_DRIVERS";
export const GET_DRIVER = "GET_DRIVER";
export const UPDATE_DRIVER_STATUS = "UPDATE_DRIVER_STATUS";
export const GET_ALL_SURGE_PRICING = "GET_ALL_SURGE_PRICING";
export const GET_ALL_PRICINGS = "GET_ALL_PRICINGS";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_CITIES = "GET_CITIES";
export const GET_CONFIGS = "GET_CONFIGS";
export const GET_CONFIG_BY_ID = "GET_CONFIG_BY_ID";
export const UPDATE_CONFIG = "UPDATE_CONFIG";

export const GET_USER_REFERRAL_TRACKING = "GET_USER_REFERRAL_TRACKING";
export const SEND_PROMO_CODE = "SEND_PROMO_CODE";

export const GET_COUNTRY_DATA = "GET_COUNTRY_DATA";
