import axios from "axios";

const baseUrl = process.env.REACT_APP_ORDERS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");

export const getCountriesData = async () => {
  let response: any;
  await axios
    .get(`${baseUrl}/api/v1/master-data/get-countries-data`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};
