import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Button from "@mui/material/Button/Button";
import TextField from "@mui/material/TextField";
import { Grid, CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { updateReferralPlan, getReferralPlan } from "../../apis/referralPlans";
import { getAllDiscounts } from "../../apis/discounts";
import { getDiscounts } from "../../actions/discountsAction";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../types/earnings";

const EditReferralPlan = () => {
  const { referralPlan } = useSelector((state: any) => state.referralPlansReducer);
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();

  let params = useParams();
  const navigate = useNavigate();
  const [referralData, setReferralData] = useState(referralPlan);
  const [discounts, setDiscounts] = useState(state.discounts);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const [loading, setLoading] = useState(false);
  const referralId: string | undefined = params?.id;

  // Fucntion to handle the back navigation
  const goToBack = () => navigate("/referral-plans");

  //Fucntion to handle the inputs and update it
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferralData({ ...referralData, [event.target.name]: event.target.value });
  };

  // Fucntion to fetch and update the referral data, used to prepopulate in the UI
  const getReferralById = async (id: any) => {
    setLoading(true);
    const data: any = await getReferralPlan(id);
    const localData = {
      name: data?.name,
      referral_count_required: data?.referral_count_required,
      discount_id: data?.discount_id,
      country_code: data?.country_code,
      region_code: data?.region_code,
    };
    setReferralData(localData);
    setCountry(data?.country_code);
    setCity(data?.region_code);
    await getDiscountData();
    setLoading(false);
  };

  // Fetch all the dicounts associated with the referral plans
  const getDiscountData = async () => {
    const data = await getAllDiscounts();
    dispatch(getDiscounts(data));
    setDiscounts(data);
  };

  useEffect(() => {
    const id = referralId !== undefined ? referralId : "";
    getReferralById(id);
  }, []);

  // Fucntion to make API request to update the referral
  const updateReferral = async () => {
    // API call
    let payload: any = {
      country_code: country?.country_code || "",
      region_code: city?.region_code || "",
      discount_id: referralData.discount_id,
      name: referralData.name,
      referral_count_required: referralData.referral_count_required,
    };
    let res = await updateReferralPlan(referralId, payload);
    if (res?.status) {
      navigate("/referral-plans");
    }
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20, marginBottom: "20px" }}>
                  Update Referral Plan
                </Typography>

                <Box
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Box
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CountryRegionSelect
                          country={country}
                          setCountry={setCountry}
                          city={city}
                          setCity={setCity}
                        />
                      </Grid>
                      <Grid item xs={3} md={4}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Name"
                          label="Name"
                          name="name"
                          value={referralData?.name}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={3} md={4}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          select
                          placeholder="Select Discount ID"
                          label="Discount ID"
                          name="discount_id"
                          value={referralData?.discount_id}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        >
                          {discounts &&
                            discounts.map((option: any) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.promo_code}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={3} md={4}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Required Referral Count"
                          label="Required Referral Count"
                          name="referral_count_required"
                          value={referralData?.referral_count_required}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItem: "center",
                    }}
                  >
                    <Button
                      style={{ marginRight: "4px", backgroundColor: "gray" }}
                      variant="contained"
                      onClick={goToBack}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={updateReferral}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditReferralPlan;
