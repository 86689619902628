import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import CountryRegionSelect from "../../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../../types/master";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const Country = (props: any) => {
  const { setCountryDetails, setUserType, userType } = props;
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);

  useEffect(() => {
    setCountryDetails({ country_code: country?.country_code, region_code: city?.region_code });
  }, [city, country]);

  const handleUserTypeChange = (event: any) => {
    setUserType(event.target.value);
  };
  return (
    <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
      <Box>
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
            Country Details
          </Typography>

          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              style={{
                padding: "20px",
              }}
            >
              <Grid container spacing={3}>
                <Grid item>
                  <CountryRegionSelect
                    country={country}
                    setCountry={setCountry}
                    city={city}
                    setCity={setCity}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="simple-select-label">User Type</InputLabel>
                    <Select
                      labelId="simple-select-label"
                      id="simple-select"
                      label="User Type"
                      onChange={handleUserTypeChange}
                    >
                      <MenuItem value="user">Normal User</MenuItem>
                      <MenuItem value="pro-user">Pro User</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Country;
