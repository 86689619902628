import { GET_ALL_DRIVERS, GET_DRIVER } from "../actionTypes/actionTypes";
import { DriverDetails, Car } from "../types/driver";

interface StateType {
  drivers: DriverDetails[];
  driver: DriverDetails;
}

const INITIAL_STATE: StateType = {
  drivers: [],
  driver: {
    available: false,
    driver_id: "",
    first_name: "",
    last_name: "",
    phone: 0,
    license_plate: "",
    average_rating: 0,
    created_at: "",
    updated_at: null,
    is_archived: false,
    update_at: "",
    cars: [],
  },
};

const driverReducer = (state = INITIAL_STATE, action: { type: string; payload: any }) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_DRIVERS:
      return Object.assign({}, state, { drivers: payload });
    case GET_DRIVER:
      return Object.assign({}, state, { driver: payload });
    default:
      return state;
  }
};

export default driverReducer;
