import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Autocomplete, TextField, CircularProgress } from "@mui/material";
import { getCountriesData } from "../apis/master";

const CountryRegionSelect = (props: any) => {
  const { country, setCountry, city, setCity } = props;
  const { countryData, regions } = useSelector((state: any) => state.masterReducer);
  const [countryDetails, setCountryDetails] = useState(countryData);
  const [citiesData, setCities] = useState(regions);
  const [loading, setLoading] = useState(false);
  const [countryDropdownOpen, setCountryDropdown] = useState(false);
  const [cityDropdownOpen, setCityDropdown] = useState(false);

  const loadingCountries = countryDropdownOpen && countryDetails.length === 0;
  const loadingCities = cityDropdownOpen && citiesData.length === 0;

  const onCountryChange = (newValue: any) => {
    console.log(newValue);
    if (newValue) {
      setCountry(newValue);
      const citiData: any = countryDetails.filter(
        (item: any) => item.country_name === newValue?.country_name
      );
      setCities(citiData[0].regions);
    }
  };

  const onCityChange = (value: any) => {
    setCity(value);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getCountriesData();
    // to set prepopulated values in case of edit
    filteredData(data);
    setCountryDetails(data);
    setLoading(false);
  };

  const filteredData = (data: any) => {
    console.log("filteredData", city, country, countryDetails, data);
    if (country || city) {
      const citiData: any = data.filter((item: any) => item.country_code === country);
      console.log("citiData[0]", citiData);
      setCountry(citiData?.[0]);
      const regionData: any = citiData[0]?.regions.filter((item: any) => item.region_code === city);
      console.log("regionData", regionData);
      setCity(regionData?.[0]);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "20px",
      }}
    >
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={countryDetails}
        getOptionLabel={(option: any) => option?.country_name}
        sx={{ width: 420, marginRight: "20px" }}
        open={countryDropdownOpen}
        onOpen={() => {
          setCountryDropdown(true);
        }}
        onClose={() => {
          setCountryDropdown(false);
        }}
        loading={loading}
        loadingText="Loading countries.."
        value={country}
        onChange={(event: any, newValue: any) => {
          onCountryChange(newValue);
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="Country"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCountries ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={citiesData}
        value={city}
        onChange={(event: any, newValue: any) => {
          onCityChange(newValue);
        }}
        getOptionLabel={(option: any) => option.region_name}
        sx={{ width: 420 }}
        open={cityDropdownOpen}
        onOpen={() => {
          setCityDropdown(true);
        }}
        onClose={() => {
          setCityDropdown(false);
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="Region"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingCities ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
export default CountryRegionSelect;
