import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Stepper from "@mui/material/Stepper";
import { Step, Snackbar, Alert } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import Country from "./stepper/Country";
import TypeClassic from "./stepper/TypeClassic";
import TypeXl from "./stepper/TypeXl";
import TypeVan from "./stepper/TypeVan";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { createPricing } from "../../apis/pricing";
import { useNavigate } from "react-router-dom";

const steps = ["Country", "Classic", "Green", "Van"];

const CreatePricing = () => {
  const { indices } = useSelector((state: any) => state.pricingReducer);
  const [activeStep, setActiveStep] = useState(0);
  const [countryDetails, setCountryDetails] = useState({ country_code: "", region_code: "" });
  const [userType, setUserType] = useState("user");
  const [classicTypeDetails, setClassicTypeDetails] = useState();
  const [xlTypeDetails, setXlTypeDetails] = useState();
  const [vanTypeDetails, setVanTypeDetails] = useState();
  const [open, setOpen] = useState(false);
  const [createSnackbar, setCreateSnackbar] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const navigate = useNavigate();

  const savePricing = async () => {
    const inputData = {
      country_code: countryDetails?.country_code,
      region_code: countryDetails?.region_code,
      user_type: userType,
      price_list: [classicTypeDetails, xlTypeDetails, vanTypeDetails],
    };

    const data: any = await createPricing(inputData);
    if (data.status) {
      navigate(`/pricing`);
    } else {
      //show error alert
      openCreateSnackBar();
    }
  };

  const openCreateSnackBar = () => {
    setCreateSnackbar(true);
  };
  const closeCreateSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setCreateSnackbar(false);
  };

  const openSnackBar = () => {
    setOpen(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };

  const handleNextClick = (e: any) => {
    e.preventDefault();
    if (activeStep === 0) {
      if (!countryDetails) {
        setStatusMessage("Please enter the country name");
        openSnackBar();
        return;
      } else if (!userType) {
        setStatusMessage("Please enter the user type");
        openSnackBar();
        return;
      }
      setActiveStep((currStep) => (currStep <= 3 ? currStep + 1 : currStep));
    } else if (activeStep === 1) {
      if (!classicTypeDetails) {
        setStatusMessage("Please enter the details");
        openSnackBar();
        return;
      }
      setActiveStep((currStep) => (currStep <= 3 ? currStep + 1 : currStep));
    } else if (activeStep === 2) {
      if (!xlTypeDetails) {
        setStatusMessage("Please enter the details");
        openSnackBar();
        return;
      }
      setActiveStep((currStep) => (currStep <= 3 ? currStep + 1 : currStep));
    } else if (activeStep === 3) {
      if (!vanTypeDetails) {
        setStatusMessage("Please enter the details");
        openSnackBar();
        return;
      }
      setActiveStep((currStep) => (currStep <= 3 ? currStep + 1 : currStep));
    }
  };

  const handleBackClick = (e: any) => {
    e.preventDefault();
    setActiveStep((currStep) => (currStep >= 0 ? currStep - 1 : currStep));
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Stepper activeStep={activeStep} className="stepper" alternativeLabel>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <Country
              setCountryDetails={setCountryDetails}
              countryDetails={countryDetails}
              setUserType={setUserType}
              userType={userType}
            />
          )}

          {activeStep === 1 && (
            <TypeClassic
              setClassicTypeDetails={setClassicTypeDetails}
              classicTypeDetails={classicTypeDetails}
            />
          )}

          {activeStep === 2 && (
            <TypeXl setXlTypeDetails={setXlTypeDetails} xlTypeDetails={xlTypeDetails} />
          )}

          {activeStep === 3 && (
            <TypeVan setVanTypeDetails={setVanTypeDetails} vanTypeDetails={vanTypeDetails} />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {activeStep > 0 && (
              <Box>
                <Button onClick={(e) => handleBackClick(e)}>Back</Button>
              </Box>
            )}
            {activeStep <= 2 && (
              <Box>
                <Button onClick={(e) => handleNextClick(e)}>Next</Button>
              </Box>
            )}
            {activeStep === 3 && (
              <Box>
                <Button onClick={savePricing}>Submit</Button>
              </Box>
            )}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={open}
              autoHideDuration={3000}
              onClose={closeSnackBar}
            >
              <Alert
                onClose={closeSnackBar}
                severity="error"
                sx={{ width: "100%", alignItems: "center" }}
              >
                {statusMessage}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={createSnackbar}
              autoHideDuration={3000}
              onClose={closeCreateSnackBar}
            >
              <Alert
                onClose={closeCreateSnackBar}
                severity="error"
                sx={{ width: "100%", alignItems: "center" }}
              >
                Price creation failed.
              </Alert>
            </Snackbar>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreatePricing;
