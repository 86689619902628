// Importing necessary components and libraries from Material-UI and React
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Importing custom components
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";

// Importing styles for the component
import "./style.css";

// Importing API functions and Redux actions
import { setDashboardNumbers } from "../../apis/dashboard";
import { getDashboardNumbers } from "../../actions/dashboardAction";

// Importing constants related to dashboard data
import { DashboardDataConstants } from "../../types/dashboard";

// Array of background colors for the cards
const bgcolors = [
  "rgba(91, 228, 155, 0.2)",
  "rgba(0, 184, 217, 0.2)",
  "rgba(255, 171, 0, 0.2)",
  "rgba(255, 86, 48, 0.2)",
];

// Object mapping dashboard data keys to user-friendly names
const countTypes = {
  total_rides_count: "Total Rides Count",
  active_rides_count: "Active Rides Count",
  completed_rides_count: "Completed Rides Count",
  cancelled_rides_by_rider_count: "Cancelled Rides by Rider Count",
  cancelled_rides_by_driver_count: "Cancelled Rides by Driver Count",
  rides_without_driver_match_count: "Rides without Driver Match Count",
  rejected_rides_count: "Rejected Rides Count",
};

// Dashboard functional component
const Dashboard = () => {
  // Redux state and dispatch setup
  const { dashboardNumbers } = useSelector((state: any) => state.dashboardReducer);
  const dispatch = useDispatch();

  // Local state to manage dashboard data
  const [dashboardData, setDashboardData] = useState(dashboardNumbers);

  // Function to fetch and update dashboard data
  const getData = async () => {
    const data = await setDashboardNumbers();
    dispatch(getDashboardNumbers(data));
    setDashboardData(data);
  };

  // useEffect hook to fetch data on component mount
  useEffect(() => {
    getData();
  }, []);

  // JSX structure for the Dashboard component
  return (
    <Box className="mainContainer">
      {/* Side panel for navigation */}
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>

      {/* Main dashboard container */}
      <Box className="dashboardContainer">
        {/* Top navigation bar */}
        <TopBar />

        {/* Content area */}
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <h2 style={{ marginBottom: "5%" }}>Hi, Welcome back 👋</h2>

          {/* Grid layout for displaying dashboard data */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0px",
            }}
          >
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              {/* Mapping through dashboardData keys to render cards */}
              {Object.keys(dashboardData).map((key, value) => (
                <Grid item xs={2} sm={4} md={3} key={key}>
                  {/* Card component with dynamic background color */}
                  <Card
                    className="cardContainer"
                    style={{
                      backgroundColor: bgcolors[Math.floor(Math.random() * bgcolors.length)],
                    }}
                  >
                    {/* Card content with title and subtitle */}
                    <CardContent>
                      <Typography className="cardTitle">
                        {/* Displaying user-friendly count type */}
                        {countTypes[key as keyof DashboardDataConstants]}
                      </Typography>
                      <Typography className="cardSubtitle">{dashboardData[key]}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

// Exporting the Dashboard component
export default Dashboard;
