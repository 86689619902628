import axios from "axios";
import { DiscountData } from "../types/discounts";

const baseUrl = process.env.REACT_APP_PROMOTIONS_SERVICE_BASE_URL;

export const getAllDiscounts = async () => {
  let response;
  const params = {
    page: 1,
    limit: 100,
    table: "discounts",
  };
  await axios
    .get(`${baseUrl}/api/v1/discounts/get-all`, {
      params,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getDiscount = async (id: any) => {
  let response: any;
  const params = {
    table: "discounts",
  };
  await axios
    .get(`${baseUrl}/api/v1/discounts/read/${id}`, {
      params,
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updateDiscount = async (id: any, body: DiscountData) => {
  let response: any;
  const params = {
    table: "discounts",
  };
  await axios
    .patch(`${baseUrl}/api/v1/discounts/update/${id}`, body, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const createDiscount = async (body: DiscountData) => {
  let response: any;
  const params = {
    table: "discounts",
  };
  await axios
    .post(`${baseUrl}/api/v1/discounts/create`, body, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const deleteDiscount = async (id: any) => {
  let response: any;
  const params = {
    table: "discounts",
  };
  await axios
    .delete(`${baseUrl}/api/v1/discounts/delete/${id}`, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
