import {
  GET_ALL_REFERRAL_PLANS,
  GET_REFERRAL_PLAN,
  CREATE_REFERRAL_PLAN,
  UPDATE_REFERRAL_PLAN,
  DELETE_REFERRAL_PLAN,
  GET_USER_REFERRAL_TRACKING,
} from "../actionTypes/actionTypes";

const getReferralPlans = (payload: any) => {
  return {
    type: GET_ALL_REFERRAL_PLANS,
    payload: payload,
  };
};

const getReferralPlanById = (payload: any) => {
  return {
    type: GET_REFERRAL_PLAN,
    payload: payload,
  };
};

const createReferralPlan = (payload: any) => {
  return {
    type: CREATE_REFERRAL_PLAN,
    payload: payload,
  };
};

const updateReferralPlan = (payload: any) => {
  return {
    type: UPDATE_REFERRAL_PLAN,
    payload: payload,
  };
};

const deleteReferralPlan = (payload: any) => {
  return {
    type: DELETE_REFERRAL_PLAN,
    payload: payload,
  };
};

const getUserReferralTracking = (payload: any) => {
  return {
    type: GET_USER_REFERRAL_TRACKING,
    payload: payload,
  };
};

export {
  getReferralPlans,
  getReferralPlanById,
  createReferralPlan,
  updateReferralPlan,
  deleteReferralPlan,
  getUserReferralTracking,
};
