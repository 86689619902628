import { Box, Paper, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import "./style.css";

const notificationsData = [
  {
    id: 1,
    description: "Test Notification",
  },
  {
    id: 2,
    description: "Test Notification",
  },
  {
    id: 3,
    description: "Test Notification",
  },
  {
    id: 4,
    description: "Test Notification",
  },
  {
    id: 5,
    description: "Test Notification",
  },
  {
    id: 6,
    description: "Test Notification",
  },
  {
    id: 7,
    description: "Test Notification",
  },
];

const Notifications = () => {

  const getNotifications = () => {
    //API call
  }

  useEffect(() => {
    //getNotifications()
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: 18 }}>Notifcations</Typography>
            </div>
            <Box>
              {notificationsData.map((notification) => {
                return (
                  <Box 
                    key={notification.id} 
                    component={Paper} 
                    sx={{
                      marginBottom: "10px",
                      padding: "15px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500, fontSize: 14 }}>
                      {notification.description}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Notifications;
