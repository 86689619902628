import { combineReducers } from "redux";
import dashboardReducer from "./dashboardReducer";
import discountReducer from "./discountReducer";
import referralPlansReducer from "./referralPlansReducer";
import driverReducer from "./driverReducer";
import pricingReducer from "./pricingReducer";
import earningsReducer from "./earningsReducer";
import masterReducer from "./masterReducer";

// Kindly keep this list alphabetical
const rootReducer = combineReducers({
  dashboardReducer: dashboardReducer,
  discountReducer: discountReducer,
  driverReducer: driverReducer,
  earningsReducer: earningsReducer,
  masterReducer: masterReducer,
  pricingReducer: pricingReducer,
  referralPlansReducer: referralPlansReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
