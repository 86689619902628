import CSS from "csstype";

export const rootContainer: CSS.Properties = {
  width: "100%",
  padding: "20px",
  marginTop: "5%",
};

export const addSurgeEventButtonContainer: CSS.Properties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
  width: "1130px",
};

export const surgeEvent = {
  fontWeight: 700,
  fontSize: 20,
};

export const tableContainer: CSS.Properties = {
  width: "1130px",
};

export const loader: CSS.Properties = {
  display: "flex",
  justifyContent: "center",
  marginTop: "200px",
};
