import axios from "axios";

const baseUrl = process.env.REACT_APP_CARS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");

export const getAllDrivers = async (page: number, limit: number) => {
  const token = localStorage.getItem("token");
  let response;
  const params = {
    page: page + 1,
    limit: limit,
    //search: "",
  };
  await axios
    .get(`${baseUrl}/api/v1/drivers/documents`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e: any) => {
      if (e?.response?.data?.message === "Exception: '_id'") {
        response = { message: "session_expired" };
      } else response = {};
    });

  return response;
};

export const getDriver = async (id: any) => {
  const token = localStorage.getItem("token");
  let response;
  await axios
    .get(`${baseUrl}/api/v1/drivers/documents/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

interface vtcStatusBody {
  vtcStatus: string;
  remark?: string;
}

export const updateVtcStatus = async (id: any, body: vtcStatusBody) => {
  const token = localStorage.getItem("token");
  let response: any;
  let AUTH_SERVICE_BASE_URL = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
  await axios
    .put(`${AUTH_SERVICE_BASE_URL}/auth/v1/users/update-vtc-status/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
      throw e;
    });

  return response;
};

export const getStripeBankAccount = async (body: any, country: string) => {
  let key =
    country == "france"
      ? process.env.REACT_APP_STRIPE_SECRET_KEY_FRANCE
      : process.env.REACT_APP_STRIPE_SECRET_KEY_UK;
  let response: any;
  await axios
    .post(`https://api.stripe.com/v1/tokens`, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${key}`,
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
      //alert("Some error occurred: " + e?.response?.data?.error?.message);
      throw e;
    });

  return response;
};

export const getStripeAccount = async (body: any, country: string) => {
  let key =
    country == "france"
      ? process.env.REACT_APP_STRIPE_SECRET_KEY_FRANCE
      : process.env.REACT_APP_STRIPE_SECRET_KEY_UK;
  let response: any;
  await axios
    .post(`https://api.stripe.com/v1/tokens`, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${key}`,
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
      //alert("Some error occurred: " + e?.response?.data?.error?.message);
      throw e;
    });

  return response;
};

export const connectAccount = async (body: any) => {
  let API_KEY = process.env.REACT_APP_CONNECT_ACCOUNT_API_KEY;
  let PAYMENT_SERVICE_URL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
  let response: any;
  await axios
    .post(`${PAYMENT_SERVICE_URL}/connected-accounts/admin`, body, {
      headers: {
        API_KEY: API_KEY,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
      //alert("Some error occurred: " + e?.message);
      throw e;
    });

  return response;
};

export const updateDriver = async (body: any, driverId: string) => {
  let response: any;
  let AUTH_SERVICE_BASE_URL = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;
  await axios
    .put(`${AUTH_SERVICE_BASE_URL}/auth/v1/users/${driverId}`, body)
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
      throw e;
    });

  return response;
};

export const uploadImage = async (formData: any) => {
  let S3_SERVICE_URL = process.env.REACT_APP_S3_SERVICE_URL;
  let response: any;
  await axios
    .post(`${S3_SERVICE_URL}/s3/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data", withCredentials: false },
    })
    .then((res) => {
      console.log("Upload successful", res.data);
      response = res;
    })
    .catch((e) => {
      console.error("Error uploading files", e);
      response = {};
    });
  return response;
};

export const fileUploadToStripe = async (body: any) => {
  let API_KEY = process.env.REACT_APP_CONNECT_ACCOUNT_API_KEY;
  let PAYMENT_SERVICE_URL = process.env.REACT_APP_PAYMENT_SERVICE_URL;
  let response: any;
  await axios
    .post(`${PAYMENT_SERVICE_URL}/connected-accounts/uploadFile`, body, {
      headers: {
        API_KEY: API_KEY,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      response = res;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
